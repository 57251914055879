<template>
  <div class="lesson-list-page w-100">
    <basic-subheader>
      <template v-slot:filters>
        <b-row>
          <b-col>
            <basic-input
              label="Tên bài học"
              class="lesson-list-page__filter"
              placeholder="Nhập tên bài học"
              name="name"
              :value.sync="filter.name"
              @keyup.native.enter="loadData"
            />
          </b-col>
          <b-col>
            <basic-select
              label="Loại bài học"
              class="lesson-list-page__filter"
              placeholder="--- Chọn loại bài học ---"
              :options="typeOptions"
              name="type"
              :allow-empty="true"
              :value.sync="filter.type"
              value-label="text"
              track-by="value"
              changeValueByObject
              @update:value="selectType"
            />
          </b-col>
          <b-col>
            <basic-select
              label="Từ khóa"
              class="lesson-list-page__filter"
              placeholder="--- Chọn từ khóa ---"
              :options="tagOptions"
              name="tag"
              multiple
              :allow-empty="true"
              :value.sync="filter.tags"
              value-label="text"
              track-by="value"
              changeValueByObject
              @update:value="selectTags"
            />
          </b-col>
        </b-row>
      </template>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
          ref="dropdownFormFilter"
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-form>
            <b-container class="p-0">
              <b-row>
                <b-col cols="4">
                  <basic-input
                    label="Mã bài học"
                    placeholder="Nhập mã bài học"
                    name="code"
                    :value.sync="filter.code"
                  ></basic-input>
                </b-col>
                <b-col cols="4">
                  <basic-input
                    label="Tên bài học"
                    placeholder="Nhập tên bài học"
                    name="name"
                    :value.sync="filter.name"
                  ></basic-input>
                </b-col>
                <b-col cols="4">
                  <basic-select
                    label="Cấp độ bài học"
                    class="lesson-list-page__dropdown-filter"
                    placeholder="--- Chọn cấp độ bài học ---"
                    name="level"
                    :options="levelOptions"
                    :value.sync="filter.level"
                    :solid="false"
                    :allow-empty="true"
                    value-label="text"
                    track-by="value"
                    @update:value="selected($event, 'level', levelOptions)"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <basic-select
                    label="Chủ đề bài học"
                    class="lesson-list-page__dropdown-filter"
                    placeholder="--- Chọn chủ đề bài học ---"
                    name="module"
                    :options="moduleOptions"
                    :value.sync="filter.module"
                    :solid="false"
                    :allow-empty="true"
                    value-label="text"
                    track-by="value"
                    @update:value="selected($event, 'module', moduleOptions)"
                  />
                </b-col>
                <b-col cols="4">
                  <basic-select
                    label="Loại bài học"
                    class="lesson-list-page__filter"
                    placeholder="--- Chọn loại bài học ---"
                    :options="typeOptions"
                    name="type"
                    :allow-empty="true"
                    :value.sync="filter.type"
                    value-label="text"
                    track-by="value"
                    changeValueByObject
                  />
                </b-col>
                <b-col cols="4">
                  <basic-select
                    label="Trạng thái"
                    class="lesson-list-page__dropdown-filter"
                    placeholder="--- Chọn trạng thái ---"
                    name="status"
                    :options="stateOptions"
                    :value.sync="filter.status"
                    :solid="false"
                    :allow-empty="true"
                    value-label="text"
                    track-by="value"
                    @update:value="selected($event, 'status', stateOptions)"
                  />
                </b-col>
                <b-col cols="4">
                  <basic-select
                    label="Từ khóa"
                    class="lesson-list-page__dropdown-filter"
                    placeholder="--- Chọn từ khóa ---"
                    name="tag"
                    :options="tagOptions"
                    :value.sync="filter.tags"
                    :solid="false"
                    :allow-empty="true"
                    value-label="text"
                    track-by="value"
                    changeValueByObject
                    multiple
                    @update:value="selectTags($event, 'tag')"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider></b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success ml-2"
          type="button"
          @click="createItem"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm mới
        </b-button>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <b-container fluid class="lesson-list-page__body mb-6 mt-6">
        <b-row>
          <b-col>
            <div class="card card-custom gutter-b">
              <div class="card-body mt-0">
                <template-table
                  :column="column"
                  :data="data"
                  :paging="paging"
                  :tableAction="false"
                  :selectAction="false"
                  :searchAction="false"
                  @search="searchRequest"
                  @reset="resetRequest"
                  @sortBy="sortRequest"
                >
                  <template v-slot:body="{ item }">
                    <td class="cell-overflow">
                      <div class="text-overflow">
                        {{ item.code }}
                      </div>
                    </td>
                    <td class="cell-overflow">
                      <div class="text-overflow">
                        {{ item.name }}
                      </div>
                    </td>
                    <td style="width: 20px">
                      <action-dropdown
                        :value="item"
                        :show_delete="false"
                        :show_edit="isWritePermission"
                        :show_copy="isWritePermission"
                        :boundary="data.length > 2 ? 'scrollParent' : 'window'"
                        @view="viewItem"
                        @edit="editItem"
                        @copy="copyItem"
                      >
                        <template v-if="isWritePermission">
                          <b-dropdown-text
                            v-if="item.status === 1"
                            tag="div"
                            class="navi-item cursor-pointer"
                          >
                            <a
                              class="navi-link text-danger"
                              @click="showPopupChangeStatus(item, 0)"
                            >
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/power.svg"
                                />
                              </span>
                              <span class="navi-text text-danger">
                                Inactive
                              </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text
                            v-else
                            tag="div"
                            class="navi-item cursor-pointer"
                          >
                            <a
                              class="navi-link text-primary"
                              @click="showPopupChangeStatus(item, 1)"
                            >
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/power.svg"
                                />
                              </span>
                              <span class="navi-text text-primary">
                                Active
                              </span>
                            </a>
                          </b-dropdown-text>
                        </template>
                      </action-dropdown>
                    </td>
                    <td class="text-center">
                      {{ item.level }}
                    </td>
                    <td class="text-center">
                      {{ item.order }}
                    </td>
                    <td class="cell-overflow">
                      <div class="text-overflow">
                        {{ item.module }}
                      </div>
                    </td>
                    <td class="cell-overflow">
                      <div class="text-overflow">
                        {{ item.tag }}
                      </div>
                    </td>
                    <!-- <td>
                      {{ item.rating }}
                    </td> -->
                    <td>
                      <div>
                        <div class="status" :class="getStatus(item.status)">
                          {{ getStatus(item.status) }}
                        </div>
                      </div>
                    </td>
                  </template>
                </template-table>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants';

export default {
  name: 'LessonList',
  data() {
    return {
      url: {
        prepare: '/Lesson/PrepareSearchFormItem',
        list: '/Lesson',
        changeState: '/Lesson/{id}/State',
      },
      loading: false,
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        code: null,
        name: null,
        level: null,
        module: null,
        status: null,
        type: null,
        tags: null,
      },
      sort: {
        by: null,
        order: null,
      },
      orderByExtend: {
        by: null,
        order: null,
      },
      stateOptions: [],
      moduleOptions: [],
      levelOptions: [],
      typeOptions: [],
      tagOptions: [],
      column: [
        {
          key: 'code',
          label: 'Mã',
          sortable: true,
          style: {
            width: '10%',
          },
        },
        {
          key: 'name',
          label: 'Tên bài học',
          sortable: true,
          style: {
            width: '18%',
          },
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'level',
          label: 'Cấp độ',
          sortable: true,
          style: {
            width: '12%',
          },
          class: 'sort-center',
        },
        {
          key: 'order',
          label: 'Thứ tự học',
          sortable: true,
          style: {
            width: '15%',
          },
          class: 'sort-center',
        },
        {
          key: 'lessonModule',
          label: 'Chủ đề',
          sortable: true,
          style: {
            width: '15%',
          },
        },
        {
          key: 'tags',
          label: 'Từ khóa',
          sortable: false,
          style: {
            width: '15%',
          },
        },
        // {
        //   key: 'lessonReviews',
        //   label: 'Đánh giá',
        //   sortable: true,
        // },
        {
          key: 'isActive',
          label: 'Trạng thái',
          sortable: false,
          style: {
            width: '5%',
          },
        },
      ],
      data: [],
    };
  },
  computed: {
    searchParams() {
      const filter = {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        orderByExtend: this.orderByExtend.by
          ? `${this.orderByExtend.by} ${this.orderByExtend.order}`
          : null,
        code: this.filter.code,
        name: this.filter.name,
        lessonLevelId: this.filter.level?.value,
        lessonModuleId: this.filter.module?.value,
        lessonStateId: this.filter.status?.value,
        classify: this.filter.type?.value,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
      const params = new URLSearchParams();
      for (const property in filter) {
        if (filter[property]) {
          params.append(property, filter[property]);
        }
      }
      if (this.filter.tags?.length) {
        this.filter.tags.forEach((el) => {
          params.append('lessonTagIdList', el.value);
        });
      }
      return params;
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.paging.page === 1 ? this.loadData() : (this.paging.page = 1);
    },
  },
  created() {
    this.loadPrepareData();
    this.loadData();
  },
  methods: {
    createItem() {
      this.$router.push({
        name: 'lesson_create',
        params: {
          type: MODAL_TYPE.CREATE,
        },
      });
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';
        default:
          return 'draft';
      }
    },
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    resetRequest() {
      this.filter = {
        code: null,
        name: null,
        level: null,
        module: null,
        status: null,
        tags: null,
      };
      this.loadData();
      document.body.click();
    },
    sortRequest(val) {
      if (val.column === 'level' || val.column === 'lessonModule') {
        this.orderByExtend.by = val.column;
        this.orderByExtend.order = val.order;
        this.sort.by = null;
        this.sort.order = null;
      } else {
        this.sort.by = val.column;
        this.sort.order = val.order;
        this.orderByExtend.by = null;
        this.orderByExtend.order = null;
      }
      this.loadData();
    },
    viewItem(item) {
      this.$router.push({
        name: 'lesson_view',
        params: {
          id: item.id,
        },
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'lesson_edit',
        params: {
          id: item.id,
        },
      });
    },
    copyItem(item) {
      this.$router.push({
        name: 'lesson_create',
        params: {
          id: item.id,
          type: MODAL_TYPE.COPY,
        },
      });
    },
    showPopupChangeStatus(item, state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          state ? 'Active' : 'Inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            this.toggleItem(item, state);
          }
        }.bind(this),
      );
    },
    toggleItem(item, state) {
      this.$api
        .put(this.url.changeState.replace('{id}', item.id), {
          id: item.id,
          state: state,
        })
        .then((res) => {
          if (res.error) {
            this.$toastr.e({
              title: 'Lỗi',
              msg: res.error.message,
            });
            return;
          }
          this.loadData();
        });
    },
    loadPrepareData() {
      this.$api.get(this.url.prepare).then((res) => {
        let data = res.data;
        this.levelOptions = this.getDropdownOptions(data.lessonLevels);
        this.moduleOptions = this.getDropdownOptions(data.lessonModules);
        this.stateOptions = this.getDropdownOptions(data.lessonStates);
        this.tagOptions = this.getDropdownOptions(data.lessonTags);
        this.typeOptions = this.getDropdownOptions(data.lessonTypes);
      });
    },
    getDropdownOptions(array) {
      return array.map((item) => {
        return {
          text: item.text,
          value: item.value,
        };
      });
    },
    selected(value, formProperty, optionsArr) {
      this.filter[formProperty] = value
        ? optionsArr.find((el) => el.value == value)
        : null;
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get(this.url.list, {
          params: this.searchParams,
        })
        .then((res) => {
          let data = res.data;
          this.data = data.map((item) => {
            return {
              code: item.code,
              id: item.id,
              name: item.name,
              level: item.lessonLevel?.name,
              module: item.lessonModule?.name,
              order: item.order,
              rating: item.rating,
              status: item.status,
              tag: this.getTags(item.lessonTagMappings),
            };
          });
          this.paging.page = res.meta.page;
          this.paging.pageSize = res.meta.size;
          this.paging.total = res.meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
    },
    getTags(tags) {
      if (tags && !tags.length) return null;
      return tags.map((el) => el.tag?.name).join(', ');
    },
    selectTags(value, type) {
      if (!value || !value.length) this.filter.tags = null;
      if (type) return;
      this.loadData();
    },
    selectType() {
      this.loadData();
    },
  },
};
</script>

<style lang="scss">
.lesson-list-page {
  .lesson-list-page__filter {
    width: 250px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .lesson-list-page__dropdown-filter {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.dropdown-form-filter {
  .dropdown-menu {
    .container {
      width: 850px;
    }
  }
}

.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sort-center {
  .sorting {
    justify-content: center;
  }
}
</style>
